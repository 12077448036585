<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo" href="/">
        <b-img
          :src="require('@/assets/images/logo/nycpg-logo-red.svg')"
          fluid
          style="height: 40px;"
        />
        <!-- <vuexy-logo /> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img-lazy
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Reset your password 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your user account's verified email address and we will send you a password reset link.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="disabled"
              >
                Send password reset email
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <!-- <b-link :to="{name:'auth-login'}"> -->
              <b-button
                type="submit"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                :to="{name:'auth-login'}"
              >
              <feather-icon icon="ChevronLeftIcon" /> Back To Login
              </b-button>
            <!-- </b-link> -->
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BImgLazy,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { auth } from '@/firebase'

export default {
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      disabled: true
    }
  },
  metaInfo: {
    meta: [
      { name: 'title', content: 'Olvidé Contraseña' }
    ]
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    userEmail(val) {
      this.disabled = false
    }
  },
  directives: {
    Ripple,
  },
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BImgLazy,
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    validationForm() {
      this.disabled = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          auth.sendPasswordResetEmail(this.userEmail)
          .then(doc => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Password reset link sent, please check your email.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            // console.log(error.code)
            if(error.code === "auth/wrong-password") {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: "The password is not correct",
                },
              })
            } else if(error.code === "auth/user-not-found") {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: "Incorrect email or password.",
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.message,
                },
              })
            }
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: error.message,
            //     icon: 'EditIcon',
            //     variant: 'danger',
            //   },
            // })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
